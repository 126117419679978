<template>
	<div class="app-container">
		<Box :type="'info'" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li v-html="$t('publish.import.box1')"></li>
					<li v-html="$t('publish.import.box2')"></li>
					<li>
						<strong>{{$t('publish.import.box3')}}</strong>
					</li>
					<li v-html="$t('publish.import.box4')"></li>
					<li v-html="$t('publish.import.box5')"></li>
					<li v-html="$t('publish.import.box6')"></li>
					<li>
						<strong>{{$t('publish.import.box7')}}</strong>
					</li>
					<li v-html="$t('publish.import.box8')"></li>
				</ul>
			</div>
		</Box>

		<!-- <el-card class="box-card" v-if="importProblems">
			<div slot="header" class="clearfix">
				<span>Rezultat import</span>
			</div>
			<div class="sub-title" style="font-size:17px;color:red">
				<i class="el-icon-warning"> &nbsp;</i>Produse cu probleme:
				<strong>{{importProblems.length}} produse</strong>
			</div>
			<div class="sub-title" style="font-size:17px:color:green" v-if="updatedProducts">
				<i class="el-icon-success"></i> &nbsp;Produse acceptate:
				<strong>{{updatedProducts}} produse</strong>
			</div>
			<div class="separator"></div>
			<el-table :data="importProblems" style="width: 100%" size="small" height="500">
				<el-table-column prop="line" label="Linia/Cod produs">
				</el-table-column>
				<el-table-column label="Coloane" width="260px">
					<template slot-scope="scope">
						{{scope.row.columns.join(', ').trim()}}
					</template>
				</el-table-column>
				<el-table-column prop="reason" label="Problema">
				</el-table-column>
			</el-table>
		</el-card> -->
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('publish.import.import-header')}}</span>
				<span style="float: right">
					<el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="uploadFile">
						{{$t('general.send')}}</el-button>
					<el-button type="success" size="mini" icon="el-icon-download" plain @click.native="downloadModel"
						>{{$t('publish.import.dl-model')}}</el-button>
					<el-button v-show="importType == 0" type="primary" size="mini" icon="el-icon-tickets" plain
						@click.native="downloadCelOffer" >
						{{$t('publish.import.dl-offer')}}</el-button>
				</span>
			</div>
			<div class="title">
				{{$t('publish.import.type')}}
			</div>
			<el-radio v-model="importType" label="1" border >
				{{$t('publish.import.update-price')}}</el-radio>
			<el-radio v-model="importType" label="0" border >
				{{$t('publish.import.add-product')}}</el-radio>
			<el-checkbox style="float: right" v-model="hasTVA" :label="$t('publish.import.has-tva')" border>
			</el-checkbox>
			<div class="separator"></div>
			<div v-if="importType == 0">
				<div class="title">
					{{$t('publish.import.category')}}
				</div>
				<div>
					<c-select @selected="categorySelected"></c-select>
				</div>
			</div>
			<div class="separator"></div>
			<div>
				<div class="title">
					{{$t('publish.import.select-doc')}}
				</div>
				<Box :type="'warning'" :active="true">
					<div slot="box-body">
						<ul class="rawUl">
							<li>
								<strong>{{$t('publish.import.box9')}}</strong>
							</li>
							<li class="boldRed">
								{{$t('publish.import.box10')}}!
							</li>
							<li>
								<strong>{{$t('publish.import.box11')}}</strong>
							</li>
						</ul>
					</div>
				</Box>
				<el-upload ref="uploadComp" id="importFile" list-type="picture" :auto-upload='false'
					:on-remove="removeFile" class="upload-demo" :limit="1"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					action="https://jsonplaceholder.typicode.com/posts/">
					<!-- <div slot="tip" class="el-upload__tip">Doar fisiere de tip JPG, GIF si PNG sunt acceptate</div> -->
					<el-button size="small" type="primary" icon="el-icon-document"
						>{{$t('publish.import.select-file')}}</el-button>
				</el-upload>
				<div class="separator"></div>
				<div slot="header" class="clearfix">
					<el-button type="primary" style="width:100%" icon="el-icon-check" plain @click.native="uploadFile">
						{{$t('general.send')}}</el-button>
				</div>
			</div>
			<div>
				<div class="separator"></div>
				<div class="title">
					{{$t('publish.import.import-history')}}
				</div>
				<el-row :gutter="12">
					<el-col :xs="24" :sm="12" :md="12" :lg="6">
						<div class="sub-title">{{$t('publish.import.from')}}</div>
						<el-date-picker class="fullWidth" v-model="filters.minDate.value" value-format="yyyy-MM-dd"
							type="date" :placeholder="$t('publish.import.from-ph')" size="small">
						</el-date-picker>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="6">
						<div class="sub-title">{{$t('publish.import.to')}}</div>
						<el-date-picker class="fullWidth" v-model="filters.maxDate.value" value-format="yyyy-MM-dd"
							type="date" :placeholder="$t('publish.import.to-ph')" size="small">
						</el-date-picker>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="6">
						<el-button type="primary" style="margin-top: 25px" icon="el-icon-check" size="small" plain
							@click.native="applyFilters">{{$t('general.apply')}}</el-button>
					</el-col>
				</el-row>
				<div class="center">
					<div class="mini-separator"></div>
					<el-pagination layout="prev, pager, next, total" :current-page="currentPage"
						:total="importHistoryCount" :page-size="limit" @current-change="changePage">
					</el-pagination>
					<div class="mini-separator"></div>

				</div>
				<el-table :data="importHistory" style="width: 100%" :size="size">
					<el-table-column prop="data_import" :label="$t('publish.import.import-date')">
					</el-table-column>
					<el-table-column :label="$t('publish.import.import-type')">
						<template slot-scope="scope">
							{{getImportTipe(scope.row.tip_import)}}
						</template>
					</el-table-column>
					<el-table-column label="Status">
						<template slot-scope="scope">
							<el-tag :type="ImportStatusType(scope.row.rezultate_import)">
								{{ImportStatusText(scope.row.rezultate_import)}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('general.details')">
						<template slot-scope="scope">
							<span v-if="parseImportResults(scope.row.rezultate_import).status === 'totalSuccess'">
								<!-- {{parseImportResults(scope.row.rezultate_import).updated
								}} {{parseImportResults(scope.row.rezultate_import).updated > 1 ? $t('publish.import.products')
								: $t('publish.import.product')}} {{parseImportResults(scope.row.rezultate_import).updated > 1 ? ' ' +
								$t('publish.import.accepted-1') : ' ' + $t('publish.import.accepted-2')}} -->
								<el-button @click.native="getHistoryDetails(scope.row.id)" type="success" size="mini"
									icon="el-icon-download" plain>{{$t('publish.import.dl-report')}}</el-button>
							</span>
							<span v-if="parseImportResults(scope.row.rezultate_import).status === 'partialSuccess'">
								<el-button @click.native="getHistoryDetails(scope.row.id)" type="warning" size="mini"
									icon="el-icon-download" plain>{{$t('publish.import.dl-report')}}</el-button>
							</span>
							<span v-if="parseImportResults(scope.row.rezultate_import).status === 'totalFail'">
								<!-- {{$t('publish.import.no-product')}} -->
								<el-button @click.native="getHistoryDetails(scope.row.id)" type="danger" size="mini"
									icon="el-icon-download" plain>{{$t('publish.import.dl-report')}}</el-button>
							</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="center">
					<div class="mini-separator"></div>
					<el-pagination layout="prev, pager, next, total" :current-page="currentPage"
						:total="importHistoryCount" :page-size="limit" @current-change="changePage">
					</el-pagination>
				</div>
			</div>
		</el-card>
		<el-dialog :title="$t('publish.import.import-result')" :visible.sync="importReportDialog" width="90%">
			<span v-if="importProblems">
				<el-alert :title="importReportTitle.msg" :type="importReportTitle.type" show-icon>
				</el-alert>
				<div class="separator"></div>
				<div class="sub-title" style="font-size:17px;color:red">
					<i class="el-icon-warning"> &nbsp;</i>{{$t('publish.import.product-prob')}}:
					<strong>{{importProblems.length}} {{$t('publish.import.products')}}</strong>
				</div>
				<div class="sub-title" style="font-size:17px;color:green" v-if="updatedProducts">
					<i class="el-icon-success"></i> &nbsp;{{$t('publish.import.product-accept')}}:
					<strong>{{updatedProducts}} {{$t('publish.import.products')}}</strong>
				</div>
				<div class="separator"></div>
				<div class="sub-title">
					<strong>{{$t('publish.import.not-accept')}}: </strong>
				</div>
				<el-table :data="importProblems" style="width: 100%;height:auto;max-height:500px;overflow-y: auto;"
					size="small">
					<el-table-column prop="line" :label="$t('publish.import.line')">
					</el-table-column>
					<el-table-column :label="$t('publish.import.columns')" width="260px">
						<template slot-scope="scope">
							{{scope.row.columns.join(', ').trim()}}
						</template>
					</el-table-column>
					<el-table-column prop="reason" :label="$t('publish.import.reason')">
					</el-table-column>
				</el-table>
			</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="importReportDialog = false">{{$t('general.close')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const CategorySelect = () =>
		import('@/components/CategoriesSelect');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		getImportHistory,
		getImportModel,
		importFile,
		getHistoryDetails,
		getCelOffers
	} from '@/api/publicare';
	import {
		setParams,
		formatDate,
		slugify,
		debugPromise
	} from '@/utils/index';
	import has from 'lodash.has';
	import find from 'lodash.find';

	export default {
		components: {
			Box,
			'c-select': CategorySelect
		},
		props: ['filterQuery'],
		data() {
			return {
				importType: '0',
				hasTVA: false,
				supplierCategories: [],
				categoryValue: 0,
				categoryName: '',
				importHistory: [],
				importHistoryCount: 0,
				importProblems: false,
				updatedProducts: false,
				currentPage: 0,
				limit: 0,
				size: 'medium',
				filters: {
					minDate: {
						type: 'select',
						value: ''
					},
					maxDate: {
						type: 'select',
						value: ''
					},
					start: {
						type: 'page',
						value: 0
					}
				},
				importReportDialog: false,
				importReportTitle: {
					msg: '',
					type: 'warning'
				}
			};
		},
		methods: {
			removeFile() {
				document.getElementById('importFile').querySelector('.el-upload__input').value = '';
			},
			sendFile(forceInsert = false) {
				const file = document.getElementById('importFile').querySelector('.el-upload__input').files;
				if (file.length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('publish.import.no-file'),
					});
					return false;
				}

				const blob = file[0];
				let params = {
					type: this.importType,
				};
				if (forceInsert === true) params.forceInsert = true;
				if (parseInt(this.importType) === 0) params.category = this.categoryValue;
				if (this.hasTVA) params.hasTVA = 1;
				params = setParams(params);
				params.append('fisier', blob);
				importFile(params).then((res) => {
					if ('undefined' == typeof res) {
						this.$router.push({
							name: 'Profil public'
						}).catch(() => {});
						return;
					}
					this.resetFields();
					switch (parseInt(res.error)) {
						case 0:
							this.sbMsg({
								type: 'success',
								message: this.$t('publish.import.finished-import') + '! ' + (parseInt(res
											.message.updated) > 1 ? this.$t(
											'publish.import.accept-plural') + ' ' :
										this.$t('publish.import.accept-single') + ' ') + res.message
									.updated + (res.message.updated > 1 ? ' ' +
										this.$t('publish.import.products') : ' ' + this.$t(
											'publish.import.product')),
							});
							this.importProblems = false;
							this.getImportHistory();
							return false;

						case 3:
							this.importReportTitle.msg =
								this.$t('publish.import.partial-import');
							this.importReportTitle.type = 'warning';
							this.importReportDialog = true;
							this.importProblems = res.message.rejected;
							this.updatedProducts = parseInt(res.message.updated) > 0 ? res.message.updated : false;
							this.getImportHistory();
							return false;

						case 5:
							this.sbMsg({
								type: 'warn',
								message: this.$t('publish.import.error-import'),
							});
							this.importReportTitle.msg =
								this.$t('publish.import.partial-import');
							this.importReportTitle.type = 'error';
							this.importReportDialog = true;
							this.importProblems = res.message.rejected;
							this.updatedProducts = parseInt(res.message.updated) > 0 ? res.message.updated : false;
							this.getImportHistory();
							return false;

						default:
							this.sbMsg({
								type: 'error',
								message: res.message != null && typeof res.message === 'string' ? res
									.message : 'Eroare generala. Contactati departamentul tehnic.',
							});
							this.importProblems = false;
							return false;
					}
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'importFile'
						}
					});
					debugPromise('importFile', e);
				});
			},
			uploadFile(forceInsert = false) {
				if (parseInt(this.importType) === 0 && (typeof this.categoryValue === 'undefined' || this.categoryValue <
						1)) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('publish.import.error-category'),
					});
					return false;
				}

				if (parseInt(this.importType) === 0) {
					this.$confirm(
						this.$t('publish.import.prefix-import'),
						'Atentie', {
							confirmButtonText: this.$t('publish.import.general.yes'),
							cancelButtonText: this.$t('publish.import.general.no'),
							type: 'warning'
						}).then((res) => {
						this.sendFile(true);
					}).catch((err) => {
						this.sendFile(false);
					});
				} else {
					this.sendFile(false);
				}
			},
			resetFields() {
				this.$refs.uploadComp.clearFiles();
			},
			changePage(page) {
				this.filters.start.value = this.limit * (page - 1);
				this.currentPage = page;

				this.getImportHistory();
			},
			downloadModel() {
				const params = {
					type: this.importType
				};

				if (parseInt(this.importType) === 0) {
					if (this.categoryValue < 1 || typeof this.categoryValue === 'undefined') {
						this.sbMsg({
							type: 'warn',
							message: this.$t('publish.import.error-category'),
						});
						return false;
					}
					params.category = {
						id: this.categoryValue,
						name: this.categoryName
					};
				}
				getImportModel(params).then((res) => {
					const link = document.createElement('a');
					const file = parseInt(this.importType) === 0 ? 'model_' + slugify(this.categoryName) +
						'.xlsx' :
						'model_stoc_price.xlsx';
					link.href = res.message.file;
					link.download = file;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					link.remove();
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getImportModel',
							params
						}
					});
					debugPromise('getImportModel', e);
				});
			},
			downloadCelOffer() {
				if (this.categoryValue < 1 || typeof this.categoryValue === 'undefined') {
					this.sbMsg({
						type: 'warn',
						message: this.$t('publish.import.error-category'),
					});
					return false;
				}
				getCelOffers({
					id: this.categoryValue,
					name: this.categoryName
				}).then((res) => {
					if (!has(res, 'error')) return false;
					if (parseInt(res.error) > 0) return false;

					const link = document.createElement('a');
					const file = parseInt(this.importType) === 0 ? 'oferta-cel-' + slugify(this.categoryName) +
						'.xlsx' :
						'export-produse-cel.xlsx';


          const linkSource = `data:application/pdf;base64,${res.message.fileData}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = file;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
				});
			},
			getImportHistory() {
				const params = {
					start: this.filters.start.value
				};
				if ('undefined' != typeof this.filters.minDate.value && this.filters.minDate.value != null && this.filters
					.minDate.value !=
					'') {
					params.datastart = this.filters.minDate.value;
				}
				if ('undefined' != typeof this.filters.maxDate.value && this.filters.maxDate.value != null && this.filters
					.maxDate.value !=
					'') {
					params.dataend = this.filters.maxDate.value;
				}

				getImportHistory(params).then((res) => {
					if (typeof res.message === 'undefined') return false;
					this.importHistory = res.message.imports;
					this.importHistoryCount = res.message.total;
					this.limit = res.message.limit;
					this.currentPage = (res.message.start / res.message.limit + 1) > 0 ? res.message.start / res
						.message.limit + 1 :
						1;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getImportHistory',
							params
						}
					});
					debugPromise('getImportHistory', e);
				});
			},
			applyFilters() {
				if (typeof this.updateQuery === 'function') this.updateQuery();
				this.getImportHistory();
			},
			getHistoryDetails(id) {
				if (typeof id === 'undefined') return false;
				getHistoryDetails({
					id: id
				}).then((res) => {
					if (typeof res === 'undefined') return false;
					const link = document.createElement('a');
					const file = 'raport-' + this.getImportHistorDate(id) + '.xlsx';
					link.href = res.message.file;
					link.download = file;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					link.remove();
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getHistoryDetails',
							params: {
								id: id
							}
						}
					});
					debugPromise('getHistoryDetails', e);
				});
			},
			getImportHistorDate(id) {
				const entry = find(this.importHistory, {
					id: id
				});
				return formatDate(entry.data_import, 'dd-MM-yyyy');
			},
			getImportTipe(type) {
				return parseInt(type) === 0 ? this.$t('publish.import.import-full') : this.$t(
					'publish.import.import-price');
			},
			ImportStatusType(status) {
				try {
					status = JSON.parse(atob(status));
				} catch (e) {
					status = {
						status: null
					};
				}

				switch (status.status) {
					case 'totalSuccess':
						return 'success';

					case 'partialSuccess':
						return 'warning';

					default:
						return 'error';
				}
			},
			ImportStatusText(status) {
				try {
					status = JSON.parse(atob(status));
				} catch (e) {
					status = {
						status: null
					};
				}

				switch (status.status) {
					case 'totalSuccess':
						return this.$t('publish.import.success');

					case 'partialSuccess':
						return this.$t('publish.import.partial');

					default:
						return this.$t('publish.import.error');
				}
			},
			getSize() {
				const width = window.innerWidth ||
					document.documentElement.clientWidth ||
					document.body.clientWidth;

				if (width < 1366) return 'small';

				return 'normal';
			},
			parseImportResults(result) {
				try {
					result = JSON.parse(atob(result));
				} catch (e) {
					result = {};
				}

				return result;
			},
			categorySelected(categ) {
				if (typeof categ.categories_id !== 'undefined' && typeof categ.categories_name !== 'undefined') {
					this.categoryValue = categ.categories_id;
					this.categoryName = categ.categories_name;
				} else {
					this.categoryValue = 0;
					this.categoryName = 'Categorie';
				}
			}
		},
		mounted() {
			this.getImportHistory();
			this.size = this.getSize();
		},
		beforeDestroy() {
			EventBus.$off();
		},
	};
</script>

<style scoped>
	.el-message--warning {
		z-index: 9999 !important;
	}
</style>